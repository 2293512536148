
const Amount =Object.freeze([
  { key: 1, value: 30000 },
  { key: 2, value: 15000},
  { key: 3, value: 10000},
  { key: 4, value: 8000},

]);

export default Amount;
