export default [
    {
      value: 1,
      text: "Ödəniş gözləyən",
    },
    {
      value: 2,
      text: "Buraxılıb",
    },
    {
      value: 3,
      text: "Müddəti bitib",
    },
    {
      value: 4,
      text: "İmtina edilib",
    },
    
  ]
  

