<template>
  <div v-if="isLoading">
    <ContractSkeleton />
  </div>
  <div v-else class="contract">
    <div class="row mb-4">
      <div class="d-flex align-items-center col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <img src="@/assets/images/arrow-left.svg" class="cursor-pointer" @click="goBack" />
        <span class="h5 contract-head ms-2 mb-0">
          Müqavilə məlumatları
        </span>
      </div>
      <div class="d-flex col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 justify-content-end">
        <div class="me-3">
          <button :disabled="isLoader"
            class="btn-print" @click="downloadDocument">
            <span class="spinner-border spinner-border-sm" v-if="isLoader" />
            <img src="@/assets/images/archive-done.svg" />
            Çap et
          </button>
        </div>
        <div v-if="contract?.status==1" class="d-inline-block"  @click="goPayment" >
          <button  class="btn-contract">
            Ödə
          </button>
        </div>
      </div>
    </div>
    <div class="contract-items">
      <p class="
       contract-item-head
      ">
        Səfər sığorta müqavilə barədə məlumat
      </p>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">Müqavilənin nömrəsi</div>
            <div class="contract-item-subtitle">
              {{ contract?.policyNumber }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Sığorta şirkəti
            </div>
            <div class="contract-item-subtitle">{{ contract?.companyName }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Sığorta haqqı
            </div>
            <div class="contract-item-subtitle">{{ contract?.premiumAzn }}</div>
          </div>
        </div>
      </div>

    </div>

    <div class="contract-items mt-5">
      <p class="
       contract-item-head
      ">
        Səfər barədə məlumat
      </p>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Səfər edilən ölkə
            </div>
            <div class="contract-item-subtitle">{{ contract?.country }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Səfərin məqsədi
            </div>
            <div v-for="c in purpose" :key="c.key" :value="c.key" class="contract-item-subtitle">
              <div v-if="c.key==contract?.travelPurpose ">
                {{ c.value }}
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Sığorta məbləği
            </div>
            <div class="contract-item-subtitle">{{ contract?.sumOfInsured }} AZN</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Səfərin müddəti:
            </div>
            <div class="contract-item-subtitle">
              <span class=" d-block">
                {{ dateTime(contract?.startDate) }}
              </span>
              <span class="d-block">
                {{ dateTime(contract?.endDate) }}
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="contract-items mt-5">
      <p class="
       contract-item-head
      ">
        Şəxs barədə məlumat
      </p>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="row mt-4">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">Ad, soyad</div>
            <div class="contract-item-subtitle">
              {{ contract?.fullName }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Pasport No
            </div>
            <div class="contract-item-subtitle">{{ contract?.passportNumber }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Doğum tarixi
            </div>
            <div class="contract-item-subtitle">{{ dateTime(contract?.birthDate) }}</div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 contract-item">
            <div class="contract-item-title">
              Mobil nömrə
            </div>
            <div class="contract-item-subtitle"> {{ contract?.phone }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end  pt-2 mt-3">
      <a  href="#" class="d-flex align-items-center  contract-footer">
        <img src="@/assets/images/info-contract.svg" />
        <div @click="downloadPDF" class="h6  mb-0 ms-2">Müqavilə şərtlərinə bax</div>
      </a>
    </div>
  </div>
  <div id="contract-document" class=" p-4 mb-4">
    <img v-if="contractImage" :src="`data:image/png;base64, ${contractImage}`" style="width: 100%" />
  </div>
</template>

  
<script>
import api from "@/api/travel.api";
import travelStatues from "@/data/TravelContractStatuses";
import moment from "moment";
import { Purpose } from "@/enums";
import fileExt from "@/services/file.ext";
import ContractSkeleton from "./ContractSkeleton.vue";

export default {
  components: { ContractSkeleton },
  data: () => ({
    isLoading: false,
    isLoader:false,
    contract: null,
    statuses: travelStatues,
    contractImage: null,
    purpose: null,
  }),
  computed: {
    purpose() { return Purpose },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      const { policyNumber } = this.$route.params;

      this.isLoading = true;

      api
        .getContractByNumber(policyNumber)
        .then((data) => (this.contract = data))
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },

    downloadDocument() {
        const { policyNumber } = this.$route.params;
        this.isLoader = true;

        api.getContractPdf(policyNumber)
            .then((response) => {
                const blob = fileExt.dataURItoBlob(response.documentPdf);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'document.pdf'; 
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.log(error))
            .finally(() => (this.isLoader = false));
    },

    downloadPDF() {
      const pdfUrl = '/travel.pdf'; 
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'travel.pdf'; 
      link.click();
    },

    getStatusDesc(status) {
      return travelStatues.find((o) => o.value === status)?.text ?? "...";
    },
    dateTime(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    goBack() {
      this.$router.go(-1);
    },
    goPayment(){
      this.$router.push({
        name: "profile-travel-contracts-payment",
        query: { policyNumber:this.contract.policyNumber, companyName:this.contract.companyName, 
        premiumAzn:this.contract.premiumAzn},
      });
    }
    
  },
};
</script> 